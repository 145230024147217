import { runInAction, makeObservable, observable, action, computed } from 'mobx';
import BaseViewModel from './BaseViewModel';
import { Segment } from '../models/Segment';
import { Variable } from './VariablesPageViewModel';
import Personalization from '../models/Personalization';
import Segmentation, { Status } from '../models/Segmentation';

import WebSocketService, { WebSocketChannel } from '../services/WebSocketService';
import {
  VariableWithPersonalizations,
  VisualizationData,
  Visualization,
  PersonalizationUpdate,
  StatusUpdateMessage
} from '../types/segmentation';

export default class SegmentationResultViewModel extends BaseViewModel {
  private currentPCView: 'pc1_pc2' | 'pc2_pc3' | 'pc3_pc1' = 'pc1_pc2';

  segments: Segment[] = [];
  personalizations: Personalization[] = [];
  variables: Variable[] = [];
  segmentationId?: number = undefined;
  selectedSegmentId?: string = undefined;
  visualizationData: Visualization[] | null = [];
  isLoading = false;
  error: string | null = null;
  currentSegmentation?: Segmentation = undefined;
  task_id?: string = undefined;

  constructor(segmentationId: number) {
    super();
    this.segmentationId = segmentationId;
    makeObservable(this, {
      segments: observable,
      segmentationId: observable,
      selectedSegmentId: observable,
      variables: observable,
      personalizations: observable,
      variablesWithPersonalizations: computed,
      addPersonalization: action,
      clearData: action,
      fetchData: action,
      setSegmentationId: action,
      setSelectedSegmentId: action,
      updatePersonalization: action,
      visualizationData: observable,
      chartOptions: computed,
      currentVisualizationData: computed,
      chartSeries: computed,
      currentSegmentation: observable,
      setCurrentSegmentation: action,
      startProcessing: action,
      recreatePersonalizations: action,
      createPersonalization: action,
      deletePersonalization: action,
    });
  }

  clearData = () => {
    runInAction(() => {
      this.segments = [];
      this.variables = [];
      this.personalizations = [];
      this.visualizationData = [];
    });
  }

  setSegmentationId(segmentationId: number) {
    runInAction(() => {
      this.segmentationId = segmentationId;
      this.fetchData();
    });
    WebSocketService.subscribe(WebSocketChannel.PERSONALIZATIONS, this.handlePersonalizationUpdate);
  }

  handlePersonalizationUpdate = (event: PersonalizationUpdate) => {
    runInAction(() => {
      const personalization = this.personalizations.find(p => p.id === event.personalization_id);
      if (personalization) {
        this.personalizations = this.personalizations.map(p => p.id === event.personalization_id ? { ...p, value: event.value, busy: event.busy } : p);
      }
    });
  }

  createPersonalization = async (variable: VariableWithPersonalizations) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const response = await this.api.post(`/segmentations/${this.segmentationId}/personalizations/`, {
        segmentation_id: this.segmentationId,
        variable_id: variable.id,
        segment_id: Number(this.selectedSegmentId)
      });
      this.personalizations = [...this.personalizations, response.data];
    } catch (err) {
      this.error = `Failed to create personalization ${err}`;
    }
  }

  deletePersonalization = async (personalization: Personalization) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      await this.api.delete(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}`);
      this.personalizations = this.personalizations.filter(p => p.id !== personalization.id);
    } catch (err) {
      this.error = `Failed to delete personalization ${err}`;
    }
  }

  updatePersonalization = async (variable: VariableWithPersonalizations, personalization: Personalization, newValue: string) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const updatedPersonalization = await this.api.put<Personalization>(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}`, {
        value: newValue
      });
      this.personalizations = this.personalizations.map(p => p.id === personalization.id ? updatedPersonalization.data : p);
    } catch (err) {
      this.error = `Failed to update personalization ${err}`;
    }
  }

  duplicatePersonalization = async (variable: VariableWithPersonalizations, personalization: Personalization) => {
    if (!this.segmentationId) {
      return;
    }
    try {
      const duplicatedPersonalization = await this.api.post<Personalization>(`/segmentations/${this.segmentationId}/personalizations/${personalization.id}/duplicate`);
      this.personalizations = [...this.personalizations, duplicatedPersonalization.data];
    } catch (err) {
      this.error = `Failed to duplicate personalization ${err}`;
    }
  }

  setSelectedSegmentId(segmentId: string) {
    this.selectedSegmentId = segmentId;
  }

  get variablesWithPersonalizations(): VariableWithPersonalizations[] {
    if (!this.selectedSegmentId) {
      return [];
    }
    return this.variables.map(variable => {
      return {
        ...variable,
        personalizations: this.personalizations.filter(p => p.variable_id === variable.id && p.segment_id === Number(this.selectedSegmentId))
      };
    });
  }

  async addPersonalization(segment_id: number, variable_id: string, value: string) {
    if (!this.segmentationId) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      await this.api.put(`/personalizations`, {
        segmentation_id: this.segmentationId,
        segment_id: Number(segment_id),
        variable_id: Number(variable_id),
        value
      });
      this.fetchData();
    } catch (error) {
      // TODO: Implementation missing
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchData() {
    if (!this.segmentationId) {
      return;
    }
    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });
    try {
      const [response, variables, personalizations, visualization] = await Promise.all([
        this.api.get<Segment[]>(`/segmentations/${this.segmentationId}/segments`),
        this.api.get<Variable[]>(`/variables`),
        this.api.get<Personalization[]>(`/segmentations/${this.segmentationId}/personalizations`),
        this.api.get<Visualization[]>(`/segmentations/${this.segmentationId}/visualization`)
      ]);

      runInAction(() => {
        this.segments = response.data;
        this.personalizations = personalizations.data;
        this.variables = variables.data;
        this.visualizationData = visualization.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = `Failed to fetch segments. Please try again later. ${error}`;
        this.isLoading = false;
      });
    }
  }

  get currentVisualizationData(): VisualizationData[] {
    if (!this.visualizationData) {
      return [];
    }
    const data = this.visualizationData.find(v => v.pc_combination === this.currentPCView)?.cluster_data ?? [];
    return data
  }

  private hexToRgba(hex: string, opacity = 1): string {
    // Remove the hash if present
    hex = hex.replace('#', '');

    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return rgba format
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  get chartSeries() {
    return this.currentVisualizationData.map(cluster => ({
      label: cluster.name,
      data: cluster.data.map(point => ({
        x: point.x,
        y: point.y
      })),
      backgroundColor: this.hexToRgba(cluster.fill)
    }));
  }

  get chartOptions() {
    return {
      chart: {
        id: 'apexchart-example',
        toolbar: {
          show: false
        },
        colors: this.currentVisualizationData.map(cluster => cluster.fill),
      },
      tooltip: {
        enabled: false
      },
      zoom: {
        type: ''
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true
          },
        },
        yaxis: {
          lines: {
            show: true
          },
        }
      },
      animations: {
        enabled: false
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        // shape: ['circle'],
        size: 2,
      },
      xaxis: {
        min: -1.1,
        max: 1.1,
        type: 'numeric',
        labels: {
          show: false
        },
      },
      yaxis: {
        min: -1.1,
        max: 1.1,
        type: 'numeric',
        labels: {
          show: false
        },
      },
    };
  }

  setCurrentSegmentation = (segmentation: Segmentation) => {
    this.currentSegmentation = segmentation;
    WebSocketService.subscribe(WebSocketChannel.SEGMENTATIONS, this.handleStatusUpdate);
  }

  handleStatusUpdate = (data: StatusUpdateMessage) => {
    runInAction(() => {
      if (this.currentSegmentation && this.currentSegmentation.id === data.segmentation_id) {
        this.currentSegmentation.status = data.status;

        // Fetch new data when processing is complete
        if (data.status === Status.PROCESS_DONE) {
          this.fetchData();
        }
      }
    });
  }

  startProcessing = async () => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      const response = await this.api.post<{ task_id: string }>(
        `/segmentations/${this.currentSegmentation.id}/run`
      );
      this.task_id = response.data.task_id;
      this.clearData(); // Clear existing data when starting new processing
    } catch (err) {
      this.error = `Failed to start ${err}`;
    }
  }

  recreatePersonalizations = async (variable: VariableWithPersonalizations, personalization: Personalization) => {
    if (!this.currentSegmentation) {
      return;
    }
    try {
      await this.api.post<{ task_id: string }>(
        `/segmentations/${this.currentSegmentation.id}/personalizations/${personalization.id}/rerun`
      );
    } catch (err) {
      this.error = `Failed to recreate personalizations ${err}`;
    }
  }

  dispose = () => {
    WebSocketService.unsubscribe(WebSocketChannel.PERSONALIZATIONS, this.handlePersonalizationUpdate);
    WebSocketService.unsubscribe(WebSocketChannel.SEGMENTATIONS, this.handleStatusUpdate);
  }
}
