import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Button
} from '@nextui-org/react';
import { ColumnSize } from "@react-types/table";
import { Status } from '../models/Segmentation';
import { VariableWithPersonalizations } from '../types/segmentation';
import { EditIcon } from '../assets/icons/EditIcon';
import { RerunIcon } from '../assets/icons/Rerun';
import { DuplicateIcon } from '../assets/icons/DuplicateIcon';
import { DeleteIcon } from '../assets/icons/DeleteIcon';
import { getTypedFormData } from '../utils/TypeFormData';
import Personalization from '../models/Personalization';

interface SegmentPersonalizationComponentProps {
  error: string | null;
  isLoading: boolean;
  onCreatePersonalization: (variable: VariableWithPersonalizations) => void;
  onDeletePersonalization: (personalization: Personalization) => void;
  onRecreatePersonalizations: (variable: VariableWithPersonalizations, personalization: Personalization) => void;
  onUpdatePersonalization?: (variable: VariableWithPersonalizations, personalization: Personalization, newValue: string) => void;
  onDuplicatePersonalization?: (variable: VariableWithPersonalizations, personalization: Personalization) => void;
  selectedSegmentId?: string;
  status?: Status;
  variablesWithPersonalizations?: VariableWithPersonalizations[];
}

type ColumnKey = 'name' | 'result'; // | 'actions';
const tableColumns: { key: ColumnKey, label: string, width: ColumnSize | null }[] = [
  { key: 'name', label: 'Name', width: 300 },
  { key: 'result', label: 'Result', width: null },
  // { key: 'actions', label: 'Actions', width: 100 }
];

type FormData = {
  value: string;
}

const SegmentPersonalizationComponent = observer((props: SegmentPersonalizationComponentProps) => {
  const {
    selectedSegmentId,
    status,
    isLoading,
    error,
    variablesWithPersonalizations,
    onRecreatePersonalizations,
    onUpdatePersonalization,
    onDuplicatePersonalization,
    onDeletePersonalization,
    onCreatePersonalization
  } = props;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState<VariableWithPersonalizations | null>(null);
  const [selectedPersonalization, setSelectedPersonalization] = useState<Personalization | null>(null);

  const handleRecreatePersonalizations = useCallback((variable: VariableWithPersonalizations, personalization: Personalization) => {
    onRecreatePersonalizations(variable, personalization);
  }, [onRecreatePersonalizations]);

  const handleDuplicatePersonalization = useCallback((variable: VariableWithPersonalizations, personalization: Personalization) => {
    if (onDuplicatePersonalization) {
      onDuplicatePersonalization(variable, personalization);
    }
  }, [onDuplicatePersonalization]);

  const handleDeletePersonalization = useCallback((personalization: Personalization) => {
    if (onDeletePersonalization) {
      onDeletePersonalization(personalization);
    }
  }, [onDeletePersonalization]);

  const handleEditClick = useCallback((variable: VariableWithPersonalizations, personalization: Personalization) => {
    setSelectedVariable(variable);
    setSelectedPersonalization(personalization);
    setIsEditModalOpen(true);
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = getTypedFormData<FormData>(event.currentTarget);
    if (selectedVariable && selectedPersonalization && onUpdatePersonalization) {
      onUpdatePersonalization(selectedVariable, selectedPersonalization, formData.get('value'));
    }
    setIsEditModalOpen(false);
  }

  const renderCell = useCallback((variable: VariableWithPersonalizations, columnKey: ColumnKey) => {
    switch (columnKey) {
      case 'name':
        return (
          <div className='flex flex-col'>
            <span className='text-sm'>{variable.name}</span>
          </div>
        )
      case 'result':
        return (
          <div className='flex flex-col gap-2'>
            {variable.personalizations?.length === 0 && (
              <div className="flex">
                <Button color="primary" onPress={() => onCreatePersonalization(variable)}>Create Personalization</Button>
              </div>
            )}
            {variable.personalizations?.map(personalization => {
              return (personalization.busy ? (
                <Spinner />
              ) : (
                <div key={personalization.id} className='flex flex-row justify-between'>
                  <span className='text-sm'>{personalization.value}</span>
                  <div className="relative flex items-center gap-2">
                    <Tooltip content="Recreate personalization">
                      <span onClick={() => handleRecreatePersonalizations(variable, personalization)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                        <RerunIcon />
                      </span>
                    </Tooltip>
                    <Tooltip content="Edit personalization">
                      <span onClick={() => handleEditClick(variable, personalization)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                        <EditIcon />
                      </span>
                    </Tooltip>
                    <Tooltip content="Duplicate personalization">
                      <span onClick={() => handleDuplicatePersonalization(variable, personalization)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                        <DuplicateIcon />
                      </span>
                    </Tooltip>
                    <Tooltip content="Delete personalization">
                      <span onClick={() => handleDeletePersonalization(personalization)} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                        <DeleteIcon />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              ))
            })}
          </div>
        )
      default:
        return "nothing"
    }
  }, [
    handleRecreatePersonalizations,
    handleDuplicatePersonalization,
    handleEditClick,
    handleDeletePersonalization,
    onCreatePersonalization
  ]);

  if (error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <ModalHeader>Edit Personalization</ModalHeader>
                <ModalBody>
                  <p className="text-sm">Variable: {selectedVariable?.name}</p>
                  <p className="text-sm">Personalization: {selectedPersonalization?.value}</p>
                  <Textarea
                    defaultValue={selectedPersonalization?.value}
                    name="value"
                    label="Personalization Value"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="flat" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className='flex flex-col h-full ml-2'>
        <h1 className='text-2xl font-bold'>Personalizations</h1>
        {(status !== Status.PROCESS_DONE && status !== Status.ERROR && status !== Status.STOPPED) || isLoading && (
          <div className='flex-1 flex justify-center items-center'>
            <Spinner />
          </div>
        )}
        {status === Status.PROCESS_DONE && !isLoading && (
          <Table
            aria-label="Segmentation control table with dynamic content"
            classNames={{ base: "mt-2" }}
            layout='fixed'
          >
            <TableHeader columns={tableColumns}>
              {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody
              items={variablesWithPersonalizations}
              emptyContent={!selectedSegmentId ? "Please select a segment first." : "No variables to personalize for this segment."}
            >
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => {
                    return <TableCell>{renderCell(item, columnKey as ColumnKey)}</TableCell>
                  }}
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    </>
  );
});

export default SegmentPersonalizationComponent;