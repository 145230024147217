import AuthStore from '../stores/AuthStore';

export enum WebSocketChannel {
  SEGMENTATIONS = 'segmentations',
  PERSONALIZATIONS = 'personalizations'
}

class WebSocketService {
  private connections: Map<WebSocketChannel, WebSocket> = new Map();
  private messageHandlers: Map<WebSocketChannel, Set<(data: any) => void>> = new Map();

  connect(channel: WebSocketChannel) {
    if (this.connections.has(channel)) return;

    const url = process.env.REACT_APP_WSS_URL || 'ws://localhost:4000';
    const ws = new WebSocket(`${url}/ws/${channel}?authorization=${AuthStore.token}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.messageHandlers.get(channel)?.forEach(handler => handler(data));
    };

    this.connections.set(channel, ws);
  }

  subscribe(channel: WebSocketChannel, handler: (data: any) => void) {
    if (!this.messageHandlers.has(channel)) {
      this.messageHandlers.set(channel, new Set());
    }
    this.messageHandlers.get(channel)?.add(handler);

    // Ensure connection exists
    this.connect(channel);
  }

  unsubscribe(channel: WebSocketChannel, handler: (data: any) => void) {
    this.messageHandlers.get(channel)?.delete(handler);
  }
}

export default new WebSocketService();